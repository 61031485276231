.breadcrumb {
  background-color: transparent !important;
}

.tree, .tree ul {
  margin:0;
  padding:0;
  list-style:none
}
.tree ul {
  margin-left:1em;
  position:relative
}
.tree ul ul {
  margin-left:.5em
}
.tree ul:before {
  content:"";
  display:block;
  width:0;
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  border-left:1px solid
}
.tree li {
  margin:0;
  padding:0 1em;
  line-height:2em;
  color:@brand-primary;
  font-weight:700;
  position:relative
}
.tree ul li:before {
  content:"";
  display:block;
  width:10px;
  height:0;
  border-top:1px solid;
  margin-top:-1px;
  position:absolute;
  top:1em;
  left:0
}
.tree ul li:last-child:before {
  background:#eeeeee;
  height:auto;
  top:1em;
  bottom:0
}
.indicator {
  margin-right:5px;
}
.tree li a {
  text-decoration: none;
  color:darken(@brand-primary, 10%);
  width: 200px;
  display: inline-block;
}
.tree li button, .tree li button:active, .tree li button:focus {
  text-decoration: none;
  color:darken(@brand-primary, 10%);
  border:none;
  background:transparent;
  margin: 0;
  padding: 0;
  outline: 0;
}