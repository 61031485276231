.tabs-left-wrap{
    .tab-content{
        .tab-pane{
            display: block;
        }
    }
}

.change-save-box {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 150px;
    color: #fff;
    background-color: @brand-primary;
    z-index: 9999;
    text-align: center;
    padding: 10px;
}