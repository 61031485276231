.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #C42A2A;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

#header-bar {
    .logo{
        width:160px;
        img{
            width:100%;
            height:auto;
        }
    }

    /**
    * #.# Top Row
    *
    * Company logo and currently logged in user image is shown here
    */
    .top-row {
        padding:15px 0;
        background:white;
        .top-row-right{
            .userbox, .quick-actions{
                display: inline-block;
            }
            .user-box {
                margin-right: 30px;
                float:left;
                .user-thumb {
                    width: 60px;
                    float: left;
                    img {
                        height: 60px;
                        width: 60px;
                        .border-radius(50%);
                    }
                }
                .user-details {
                    margin-left: 15px;
                    float: left;
                    position: relative;
                     &:after{
                        content:'';
                        display: block;
                        position: absolute;
                        right:-23px;
                        top:50%;
                        margin-top:-10px;
                        height:30px;
                        width:1px;
                        background: @gray-lighter-ish;
                    }
                    .username {
                        font-size: 1.5em;
                        color: @brand-primary;
                    }
                    .useractions{
                        font-size:.8em;
                    }
                    .btn-group {
                        background-color: transparent;
                        border: none;
                        .btn-user {
                            .btn-primary;
                            font-size: 14px;
                            font-weight: bold;
                            background-color: transparent;
                            border: 0px;
                            border-right: 1px solid #d9d9d9;
                            color: #333;
                            padding: 0px 15px 0px 0px;
                            &:last-child {
                                border-right: 0px;
                                padding: 0px 0px 0px 15px;
                            }
                            &:hover {
                                background-color: transparent;
                                color: @brand-primary;
                                border-right: 1px solid #d9d9d9;
                                &:last-child {
                                    border-right: 0px;
                                }
                            }
                        }
                    }
                }
            }
            .quick-actions{
                list-style: none;
                margin:0;
                padding:0;
                text-align:right;
                margin-top:10px;
                li{
                    display:inline-block;
                    position: relative;
                    text-align: center;
                    font-size:.7em;
                    padding:0 15px;
                    a{
                        color:@gray-light;
                        span{
                            display: block;
                            margin-bottom: 5px;
                            font-size:1.8em;
                        }
                    }
                    &:last-child{
                        padding-right: 0;
                        &:after{
                            display: none;
                        }
                    }
                    &:after{
                        content:'';
                        display: block;
                        position: absolute;
                        right:-4px;
                        top:50%;
                        margin-top:-15px;
                        height:30px;
                        width:1px;
                        background: @gray-lighter-ish;
                    }
                }
            }
        }
    }

    /**
    * #.# Primary Navigation
    *
    */
    .navbar{
        margin-bottom: 0;
        border:0;
        background:@gray-lightest;
        .navbar-collapse{
            ul.navbar-nav{
                > li{
                    > a{
                        font-family:@second-font-family;
                        font-size:1.5em;
                        padding:25px 40px;
                        [class^="icon-"]{
                            font-size:.8em;
                            padding-right:10px;
                        }
                    }
                    &.dropdown{
                        .dropdown-menu{
                            border:0;
                            background: @brand-secondary;
                            width:100%;
                            padding:0;
                            li{
                                a{
                                    color:white;
                                    padding: 15px;
                                    &:hover{
                                        background: darken(@brand-secondary, 5%);
                                    }
                                }
                            }
                            .divider{
                                background: lighten(@brand-secondary, 5%);
                                margin:0;
                            }
                        }
                    }
                    &.active{
                        > a{
                            background:@brand-primary;
                            color:white;
                        }
                    }
                }
            }
        }
    }

    /**
    * #.# Title Bar
    *
    * Big red title bar for all pages
    */
    .title-row {
        height: 140px;
        background-color: @brand-primary;
        display:table;
        width:100%;
        .vertical-centered{
            height:140px;
        }
        .title{
            float:left;
            margin-left: 40px;
            color:white;
            .vertical-align();
            h1{
                margin:0;
            }
            small{
                display:block;
            }
        }
        /* For page editing button */
        .edit{
            float:right;
			margin-right:40px;
			.vertical-align();
        }
    }
}

/**
* #.# Sub nav tabs
*
* Used in template with left sidebar
*/
.sub-nav{
    background:white;
}

