.ui-datepicker{
  z-index:2 !important;
}

.no-select {
    .user-select(none);
}

.country-list {
  z-index: 4!important;
}
.intl-tel-input {
  width: 100%;
}
.select2-container--default .select2-selection--multiple {
  border-radius: 0!important;
}
.select2-selection__choice {
  border-radius: 0!important;
}
.select2-dropdown .select2-dropdown--below {
  border-radius: 0!important;
}
.date .input-group-addon {
  background: transparent !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.date input {
  border-left: 0 !important;
}

.popup-sig {
    background-color:#fff;
    z-index:1001;
    width:310px;
    height:210px;
    padding:5px;
    position:fixed;
    top:50%;
    left:50%;
    margin-left:-155px;
    margin-top:-105px;
    border:1px solid #000;
}

.sig-backdrop {
    z-index:999;
    position:fixed;
    background-color:rgba(25,25,25,.7);
    top:0;
    left:0;
    right:0;
    bottom:0;
}

.sig-controls {
    display:block;
    width:300px;
}

.search {
    width: 70%;
    position: relative;
    &:before{
        .base-icons();
        content: "\eb2d";
        position: absolute;
        right:12px;
        z-index: 9;
        top:50%;
        margin-top:-11px;
        color:@gray;
        font-size: 1.3em;
	  	cursor: pointer;
    }
    .form-control{
        padding-right:45px;
    }
}

label{
    font-weight: normal;
    font-size:1em;
}

.form-control{
    border-radius: 0 !important;
    &.input-lg{
        font-size:.9em;
    }
}

#main .v-select .dropdown-toggle {
    border-radius: 0;
    background-color: #fff;
    color: darken(#fff, 50%);
}