html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    background-color: @wrapper-bg;
    overflow-x: hidden;
}

#main {
    .content-col {
        padding: 35px 0;
        margin: 0px auto;
        background-color: @wrapper-bg;
    }
}

#filter_by, #filter, #filter_by_client { display: inline-block; height: 35px; }
#filter_by { position: relative; top: -2px;}
.report-control-wrap { padding:0 0 40px 0;}
.report-label { padding: 0; margin: 0; position: relative; top: 3px;}
.report-label h3 { margin: 0; padding: 0;}
.report-paginate { text-align: center;}
.date-filter { padding: 0 0 0 20px; display: inline-block;}
.contractor-dash-link {font-size: 1.15em;}
#client-filter {width: 100%; display: inline-block; margin-top: 20px; height: 35px;}