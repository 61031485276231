.pagination {
    margin:0;
    li {
        cursor:pointer;
        &.next, &.prev {
            a {
                border-bottom: 0;
                font-weight: bold;
                text-transform: uppercase;
                [class^="icon-"] {
                    font-size: .8em;
                }
            }
        }
        &.next {
            a {
                [class^="icon-"] {
                    padding-left: 8px;
                }
            }
        }
        &.prev {
            a {
                [class^="icon-"] {
                    padding-right: 8px;
                }
            }
        }
        &.active {
            a {
                background: none;
                border-bottom: 2px solid @brand-primary;
                color: @brand-primary;
                &:hover{
                    background:none;
                    color: @brand-primary;
                }
            }
        }
        a {
            background: none;
            border: 0;
            border-bottom: 2px solid @gray-lighter-ish;
            padding:5px;
            margin: 0 8px;
            font-size:1.02em;
            &:hover{
                background:none;
                color:@text-color;
                border-bottom-color: @text-color;
            }
        }
    }
}
