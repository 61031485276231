.nav-tabs{
    border-bottom:0;
    li{
        &.active{
            a{
                border:0;
                border-bottom:3px solid @brand-primary;
                font-weight: normal;
                &:hover, &:focus{
                    border:0;
                    border-bottom:3px solid @brand-primary;
                    outline:0;
                    font-weight: normal;
                }
            }
        }
        a{
            border:0;
            margin-right: 25px;
            &:hover, &:focus{
                border:0;
                border-bottom:3px solid @brand-secondary;
                outline:0;
                background:none;
            }
        }
    }
}


/**
* #.# Vertical Tabs
*
*/

.tabs-left-wrap{
    position: relative;
    background:white;
    .box-shadow(@shadow: 0 1px 3px rgba(0,0,0,.05));
    .tabs-left{
        position: absolute;
        left:0;
        top:0;
        bottom:0;
        width:200px;
        background: @brand-secondary;
        @media (min-width: @screen-lg-min) {
            width:250px;
        }
        li{
            float:none;
            position: relative;
            &:before{
                content:'';
            }
            &.active, &:focus, &:hover{
                a{
                    border:0;
                }
            }
            &.active{
                &:before{
                    content:'';
                    height:100%;
                    width:4px;
                    background:@brand-primary;
                    display: block;
                    position: absolute;
                    z-index:99;
                }
                a{
                    color:@text-color;
                }
            }
            a{
                margin-right: 0;
                display:block;
                padding: 20px 10px 20px 45px;
                position: relative;
                font-size: 1em;
                color:white;
                @media (min-width: @screen-lg-min) {
                    font-size:1.2em;
                }
                &:before{
                    content:'';
                    .base-icons();
                    margin-right: 10px;
                    font-size:1.2em;
                    position: absolute;
                    left:15px;
                    top:50%;
                    margin-top:-9px;
                    @media (min-width: @screen-lg-min) {
                        margin-top:-12px;
                    }
                }
            }
            &.dig-profile{
                a{
                    &:before{
                        content:'\e602';
                    }
                }
            }
            &.pipeline-profile{
                a{
                    &:before{
                        content:'\e601';
                    }
                }
            }
        }
    }
    .tab-content{
        padding-left:200px;
        min-height: 800px;
        @media (min-width: @screen-lg-min) {
            padding-left:250px;
        }
        .tab-pane{
            padding:20px 40px;
            h2, h3, h4{
                &.bordered-heading{
                    margin-bottom: 40px;
                }
            }
        }
    }
}


