.dashboard-apps {
    list-style: none;
    text-align: center;
    padding:0;
    li{
        a{
            width:31.3333%;
            text-align: center;
            float:left;
            background:white;
            padding:0;
            margin:1%;
            .box-shadow(@shadow: 0 1px 3px rgba(0,0,0,.05));
            &:hover{
                .app-icon{
                    background:@brand-primary;
                    color:white;
                }
            }
            @media (min-width: @screen-lg-min) {
                width:18%;
            }
            .app-icon{
                font-size:5.5em;
                color:@gray-light;
                padding:60px 0;
                height:230px;
                .icon-echo-admin-icons-05{
                    font-size: 1.3em;
                }
            }
            h3{
                background:@brand-secondary;
                color:white;
                margin:0;
                padding: 10px;
            }
        }
        &:nth-of-type(4), &:nth-of-type(5){
            a{
                width:48%;
                @media (min-width: @screen-lg-min) {
                    width:18%;
                }
            }
        }
    }
}
