// Mixins
// --------------------------------------------------

// Layout
//@import "mixins/custom-grid.less";

.box-sizing(){
    -webkit-box-sizing: border-box !important; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box !important;    /* Firefox, other Gecko */
    box-sizing: border-box !important;         /* Opera/IE 8+ */
}

.transition (@speed: .5s) {
    -webkit-transition: all @speed ease-in;
    -moz-transition: all @speed ease-in;
    transition: all @speed ease-in;
}

.border-radius(@data){
    -webkit-border-radius:@data;
    -moz-border-radius: @data;
    border-radius: @data;
}

.vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

// Utilities
@import "@{bootstrap}/mixins/hide-text.less";
@import "@{bootstrap}/mixins/opacity.less";
@import "@{bootstrap}/mixins/image.less";
@import "@{bootstrap}/mixins/labels.less";
@import "@{bootstrap}/mixins/reset-filter.less";
@import "@{bootstrap}/mixins/resize.less";
@import "@{bootstrap}/mixins/responsive-visibility.less";
@import "@{bootstrap}/mixins/size.less";
@import "@{bootstrap}/mixins/tab-focus.less";
@import "@{bootstrap}/mixins/reset-text.less";
@import "@{bootstrap}/mixins/text-emphasis.less";
@import "@{bootstrap}/mixins/text-overflow.less";
@import "@{bootstrap}/mixins/vendor-prefixes.less";

// Components
@import "@{bootstrap}/mixins/alerts.less";
@import "@{bootstrap}/mixins/buttons.less";
@import "@{bootstrap}/mixins/panels.less";
@import "@{bootstrap}/mixins/pagination.less";
@import "@{bootstrap}/mixins/list-group.less";
@import "@{bootstrap}/mixins/nav-divider.less";
@import "mixins/forms.less";
@import "@{bootstrap}/mixins/progress-bar.less";
@import "@{bootstrap}/mixins/table-row.less";

// Skins
@import "@{bootstrap}/mixins/background-variant.less";
@import "@{bootstrap}/mixins/border-radius.less";
@import "@{bootstrap}/mixins/gradients.less";

// Layout
@import "@{bootstrap}/mixins/clearfix.less";
@import "@{bootstrap}/mixins/center-block.less";
@import "@{bootstrap}/mixins/nav-vertical-align.less";
@import "@{bootstrap}/mixins/grid-framework.less";
@import "@{bootstrap}/mixins/grid.less";
