.st-sort-ascent:before{
  content: '\25B2';
}

.st-sort-descent:before{
  content: '\25BC';
}

.data-table{
    border:0;
    margin-bottom:0;
    thead{
        tr{
            &:first-child{
                border-top: 15px solid #fff !important;
            }
            th{
                background:@gray-lightest;
                border-bottom:0;
                &:first-child{
                    border-left: 15px solid #fff !important;
                }
                &:last-child{
                    border-right: 15px solid #fff !important;
                }
            }
        }
    }
    tbody{
        tr{
            &:first-child{
                td{
                    border-top:0;
                }
            }
            &:last-child{
                td{
                    border-bottom: 15px solid #fff !important;
                }
            }
            td{
                &:first-child{
                    border-left: 15px solid #fff !important;
                }
                &:last-child{
                    border-right: 15px solid #fff !important;
                }
            }
        }
    }
    tfoot{
        tr{
            td{
                border: 0;
            }
        }
    }
    .status{
        color:white;
        display: inline-block;
        padding:3px 10px;
        font-size: .8em;
    }
    .no-expiry{
        background:@brand-success;
    }
    .expired{
        background: @brand-danger;
    }

    .expired-30{
        background: @brand-warning;
    }

    .expired-90{
        background: @brand-warning2;
    }

    .expired-over-90{
        background: @brand-info;
    }
}
