
@font-face {
    font-family: 'bebas_neuebold';
    src: url('../fonts/bebasneue/bebasneue_bold-webfont.eot');
    src: url('../fonts/bebasneue/bebasneue_bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bebasneue/bebasneue_bold-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue/bebasneue_bold-webfont.woff') format('woff'),
         url('../fonts/bebasneue/bebasneue_bold-webfont.ttf') format('truetype'),
         url('../fonts/bebasneue/bebasneue_bold-webfont.svg#bebas_neuebold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'bebas_neue_regularregular';
    src: url('../fonts/bebasneue/bebasneue_regular-webfont.eot');
    src: url('../fonts/bebasneue/../fonts/bebasneue/bebasneue_regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bebasneue/bebasneue_regular-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue/bebasneue_regular-webfont.woff') format('woff'),
         url('../fonts/bebasneue/bebasneue_regular-webfont.ttf') format('truetype'),
         url('../fonts/bebasneue/bebasneue_regular-webfont.svg#bebas_neue_regularregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

h1,h2,h3,h4{
    &.bordered-heading{
        border-bottom:2px solid @gray-lighter-ish;
    }
}

a[ng-click]{
    cursor: pointer;
}