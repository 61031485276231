.left-sidebar{
    margin-top:-202px!important;
    padding-right:20px;
    .left-sidebar-img{
        background:white;
        padding:10px;
	  	min-height: 150px;
        .box-shadow(@shadow: 0 1px 3px rgba(0,0,0,.05));
        .img-secondary{
            text-align: center;
            padding:10px 0 0;
        }
    }
    h3{
        margin:30px 0 20px;
    }
    .row{
        margin-bottom:10px;
    }

    &.no-photo {
        margin-top: -56px !important;
    }
}
