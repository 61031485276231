.zero-top{
    margin-top:0;
    padding-top:0;
}

.zero-bottom{
    margin-top:0;
    padding-bottom: 0;
}

.top20{
    margin-top:20px;
}

.top40{
    margin-top:40px;
}

.bottom20{
    margin-bottom:20px;
}

.bottom40{
    margin-bottom:40px;
}

.push-right{
    padding-right:20px;
}

.push-left{
    padding-left:20px;
}

.container{
    padding: 0 40px;
}

img{
    display:inline-block!important;
}

.row-space {
    margin-top: 8px;
}