.btn{

    [class^="icon-"]{
        display: inline-block;
        margin-right:8px;
        position: relative;
    }

    &.btn-default{
        border:0;
        &.btn-lg{
            font-size:1.1em;
            padding:12px 25px;
            line-height: 1.5em;
            [class^="icon-"]{
                font-size:1.2em;
            }
        }
        &.btn-table{
            [class^="icon-"]{
                top:3px;
            }
        }
    }

    &.btn-primary{
        border:0;
        &:hover{
            border:0;
        }
    }

    &.btn-edit{
        background: darken(@brand-primary, 5%);
        padding:10px 20px;
        font-size:.9em;
        &:hover{
            background: darken(@brand-primary, 10%);
        }
        [class^="icon-"]{
            font-size:.8em;
        }
    }
}
