.panel-default {
    border:0;
    position: relative;
    box-shadow:0 0 0 0;
    margin-bottom:20px;
    .row{
        margin-bottom: 25px;
        &:last-of-type{
            margin-bottom: 0;
        }
    }
    .panel-left-col{
        padding-right:30px;
    }
    .panel-right-col{
        padding-left:30px;
    }
    .panel-heading {
        color: #333333;
        background:@gray-lightest;
        border-color: #dddddd;
        border:1px solid @gray-lighter;
        position: relative;
        padding: 10px 15px;
        font-family:@second-font-family;
        font-size:2em;
        line-height: 1em;
        &:before{
            content:'';
            height:4px;
            width:100%;
            display: block;
            background: @brand-primary;
            position: absolute;
            left:0;
            top:-3px;
        }
    }
    .panel-body{
        border:1px solid @gray-lighter;
        border-top:0;
        padding:20px 15px;
    }
}

.panel-body{
    background:white;
    padding:15px;
}

.panel-data {
    border: none;
    background: none;
    box-shadow: 0 0 0;
    select {
        padding: 3px 0px 3px 3px;
        margin: 0px 5px;
    }
    label {
        line-height: 26px;
    }
    .panel-heading {
        border-top: none;
        padding:20px 0;
        border: 0;
    }
    .panel-body{
        .box-shadow(@shadow: 0 1px 3px rgba(0,0,0,.05));
    }
    .panel-footer {
        color: #333333;
        padding: 20px 0;
        background:none;
        .paging_bootstrap {
            text-align: center;
            .pagination {
                margin: 0px;
            }
        }
        label {
            font-size:.9em;
        }
        .entries-total {
            text-align: right;
            font-size:.9em;
        }
    }
}

.panel-title {
    font-weight: bold;
    color: @gray;
}

.panel-login {
    margin-top: 100px;
    .panel-heading {
        padding: 25px;
        background-color: @logo-background;
        text-align: center;
        img {
            .img-responsive;
            max-height: 200px;
            margin: 0 auto;
        }
    }
    .panel-body {}
    .panel-footer {
        color: #333333;
        background-color: #fff;
        border-color: #dddddd;
        border-top: 4px solid @brand-primary;
        padding: 7px;
        .pagination {
            margin: 0px;
        }
    }
}

.panel-form {
    border:0;
    position: relative;
    box-shadow:0 0 0 0;
    margin-bottom:20px;
    .row{
        margin-bottom: 25px;
        &:last-of-type{
            margin-bottom: 0;
        }
    }
    .panel-left-col{
        padding-right:30px;
    }
    .panel-right-col{
        padding-left:30px;
    }
    .panel-heading {
        color: #333333;
        background:@gray-lightest;
        border-color: #dddddd;
        border:1px solid @gray-lighter;
        position: relative;
        padding: 10px 15px;
        font-family:@second-font-family;
        font-size:2em;
        line-height: 1em;
        &:before{
            content:'';
            height:4px;
            width:100%;
            display: block;
            background: @brand-primary;
            position: absolute;
            left:0;
            top:-3px;
        }
    }
    .panel-body{
        border:1px solid @gray-lighter;
        border-top:0;
        padding:20px 15px;
    }

    input[type="submit"].panel-footer, button.panel-footer {
	  	.button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border);
    }
    .btn-primary {background: @btn-primary-bg; color: @btn-primary-color; border: @btn-primary-border;}
}